import {
  ABOUT_ME_SECTION,
  CERTIFICATION_ENTITY,
  CERTIFICATION_SECTION,
  CONTACT_SECTION,
  EDUCATION_ENTITY,
  EDUCATION_SECTION,
  EXPERIENCE_ENTITY,
  EXPERIENCE_SECTION,
  TEMPLATE_FIRST,
  HEADER_SECTION,
  INTEREST_ENTITY,
  INTEREST_SECTION,
  LANGUAGE_ENTITY,
  LANGUAGE_SECTION,
  POSITION_LEFT,
  POSITION_RIGHT,
  POSITION_TOP,
  PROFILE_ENTITY,
  TEMPLATE_SECOND,
  SKILL_ENTITY,
  SKILL_SECTION,
  TEMPLATE_ANDROMEDA,
  LOGO_SECTION,
  REFERENCE_SECTION,
  REFERENCE_ENTITY,
} from '../reducers/editor';

export function getTemplateSections(templateId) {
  const TEMPLATES_SECTIONS = {
    [TEMPLATE_FIRST]: [
      {
        name: HEADER_SECTION,
        entity: PROFILE_ENTITY,
        height: 55,
        wrapItems: false,
      },
      {
        name: EXPERIENCE_SECTION,
        entity: EXPERIENCE_ENTITY,
        height: 55,
        wrapItems: true,
      },
      {
        name: EDUCATION_SECTION,
        entity: EDUCATION_ENTITY,
        height: 55,
        wrapItems: true,
      },
      {
        name: SKILL_SECTION,
        entity: SKILL_ENTITY,
        height: 0,
        wrapItems: false,
      },
      {
        name: LANGUAGE_SECTION,
        entity: LANGUAGE_ENTITY,
        height: 55,
        wrapItems: true,
      },
      {
        name: CERTIFICATION_SECTION,
        entity: CERTIFICATION_ENTITY,
        height: 55,
        wrapItems: true,
      },
      {
        name: INTEREST_SECTION,
        entity: INTEREST_ENTITY,
        height: 0,
        wrapItems: false,
      },
      {
        name: REFERENCE_SECTION,
        entity: REFERENCE_ENTITY,
        height: 0,
        wrapItems: false,
      },
    ],
    [TEMPLATE_SECOND]: [
      {
        name: HEADER_SECTION,
        entity: PROFILE_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_TOP,
      },
      {
        name: CONTACT_SECTION,
        entity: PROFILE_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_LEFT,
      },
      {
        name: ABOUT_ME_SECTION,
        entity: PROFILE_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_RIGHT,
      },
      {
        name: SKILL_SECTION,
        entity: SKILL_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_LEFT,
      },
      {
        name: EXPERIENCE_SECTION,
        entity: EXPERIENCE_ENTITY,
        height: 64,
        wrapItems: true,
        position: POSITION_RIGHT,
      },
      {
        name: EDUCATION_SECTION,
        entity: EDUCATION_ENTITY,
        height: 64,
        wrapItems: true,
        position: POSITION_RIGHT,
      },
      {
        name: LANGUAGE_SECTION,
        entity: LANGUAGE_ENTITY,
        height: 64,
        wrapItems: true,
        position: POSITION_LEFT,
      },
      {
        name: CERTIFICATION_SECTION,
        entity: CERTIFICATION_ENTITY,
        height: 64,
        wrapItems: true,
        position: POSITION_LEFT,
      },
      {
        name: INTEREST_SECTION,
        entity: INTEREST_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_LEFT,
      },
      {
        name: REFERENCE_SECTION,
        entity: REFERENCE_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_RIGHT,
      },
    ],
    [TEMPLATE_ANDROMEDA]: [
      {
        name: HEADER_SECTION,
        entity: PROFILE_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_TOP,
      },
      {
        name: LOGO_SECTION,
        entity: PROFILE_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_LEFT,
      },
      {
        name: CONTACT_SECTION,
        entity: PROFILE_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_LEFT,
      },
      {
        name: ABOUT_ME_SECTION,
        entity: PROFILE_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_RIGHT,
      },
      {
        name: SKILL_SECTION,
        entity: SKILL_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_LEFT,
      },
      {
        name: EXPERIENCE_SECTION,
        entity: EXPERIENCE_ENTITY,
        height: 64,
        wrapItems: true,
        position: POSITION_RIGHT,
      },
      {
        name: EDUCATION_SECTION,
        entity: EDUCATION_ENTITY,
        height: 64,
        wrapItems: true,
        position: POSITION_RIGHT,
      },
      {
        name: LANGUAGE_SECTION,
        entity: LANGUAGE_ENTITY,
        height: 64,
        wrapItems: true,
        position: POSITION_LEFT,
      },
      {
        name: CERTIFICATION_SECTION,
        entity: CERTIFICATION_ENTITY,
        height: 64,
        wrapItems: true,
        position: POSITION_LEFT,
      },
      {
        name: INTEREST_SECTION,
        entity: INTEREST_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_LEFT,
      },
      {
        name: REFERENCE_SECTION,
        entity: REFERENCE_ENTITY,
        height: 0,
        wrapItems: false,
        position: POSITION_RIGHT,
      },
    ],
  };

  return TEMPLATES_SECTIONS[templateId];
}
